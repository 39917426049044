import React from "react"
import Meta from "../components/meta"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Title from "../components/title"
import Back from "../components/back"

export default function Home() {
  return (
    <>
      <Meta title="システム開発事例紹介" description="業務分析・コンサルティングからソフトウェア開発、システム構築まで一貫して提供します。経験豊富なエンジニアが初期段階より将来を見据えた設計を行い、品質・安定性の高いシステムはお客様から高い評価をいただいております。" />
      <div className="flex flex-col h-screen">
        <header>
          <Navbar hasContact={false} />
        </header>
        <main className="mt-14 reveal fade-bottom">
          <div className="container max-w-5xl mx-auto py-4 px-4">
            <Title title="システム開発事例紹介" />

            <div className="bg-white rounded-lg mb-4 flex flex-wrap">
              <div className="w-full p-6">
                <h3 className="card-heading text-xl md:text-3xl font-bold leading-none">
                  ベルクラフトだからできること
                </h3>
                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  業務分析・コンサルティングからソフトウェア開発、システム構築まで一貫して提供します。経験豊富なエンジニアが初期段階より将来を見据えた設計を行い、品質・安定性の高いシステムはお客様から高い評価をいただいております。
                </p>

                <p className="mt-4 md:mt-8 text-gray-600 leading-7">
                  お客様のニーズに合わせて課題をクリアにし、ベストな解決方法を提案いたします。
                </p>

                <h3 className="mt-4 md:mt-8 card-heading text-xl md:text-3xl font-bold leading-none">
                  事例紹介
                </h3>

                <div className="mt-4 md:mt-8 mb-4">
                  <div className="w-full flex flex-wrap">
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/laboratory-2815641_640.jpg" alt="院内物流管理のイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light">院内物流管理</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            RFID を用いた SPD と呼ばれる院内物流管理システムです。シリンジポンプなど機器の所在管理も同時に行い集中管理することで大幅な管理コスト削減に貢献しました。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/digital-marketing-1725340_640.jpg" alt="AI マーケティング SNS 分析ツールのイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light">AI マーケティング<br className="br-sp" /> SNS 分析ツール</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            従来では困難であった大量データである Twitter などの SNS 投稿を AI を用いた感情分析などを行い、キャンペーン効果測定を実施するマーケティング支援システムです。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/grocery-1232944_640.jpg" alt="無人コンビニ実証実験のイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light">無人コンビニ<br className="br-sp" />実証実験</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            ロボットやセンサー、自動認識技術の高まりにより世界中で行われている無人店舗への試み、当社もいち早く参画し関東圏にての実証実験は一定の成果を収めました。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/hands-1167612_640.jpg" alt="私鉄 QR チケット発券管理のイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light">私鉄 QR チケット<br className="br-sp" />発券管理</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            キャッシュレスでも活用されている日本発の技術である QR コードにを利用した鉄道などの観光周遊きっぷ発券システムです。サーバレスにより高い応答性と冗長性を確保しました。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/iphone-1836071_640.jpg" alt="動画配信サービスのイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light mx-2">動画配信サービス</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            昨今需要の高まる動画配信ですが MediaLive などの AWS マネージドサービスをフル活用して放送局レベルの高い品質となりました。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/2 md:w-1/3 reveal fade-bottom">
                      <div className="m-2">
                        <div>
                          <img className="case-img" src="/case/consulting-3031678_640.jpg" alt="士業事務所 DX コンサルのイメージ画像" />
                        </div>
                        <div>
                          <h5 className="mt-4 text-center heading text-marker-light mx-2">士業事務所<br className="br-sp" /> DX コンサル</h5>
                          <p className="p-4 text-gray-600 leading-7">
                            大きくデジタル化が推進されている業界ですが、業務のヒアリングからツール提案、簡単なプログラミング講習など二人三脚で、お客様に寄り添ったコンサルティングを行いました。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Back />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}
